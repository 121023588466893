<template>
  <div>
    <!-- ADD EMPLOYMENT FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Employment">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Company*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div>
                      <multiselect
                        disabled
                        v-model="company_value"
                        track-by="company_id"
                        label="company_name"
                        placeholder="Select Company"
                        @input="
                          ({ company_id }) =>
                            (this.form.company_id = company_id)
                        "
                        :options="companies"
                        name="Company Name"
                        :searchable="true"
                        @select="onChangeCompany"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.company_name }}
                        </template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Name')"
                        >{{ errors.first("Company Name") }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- USER NAME -->
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">User*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div>
                      <multiselect
                        v-model="user_value"
                        track-by="user_id"
                        label="user_name"
                        placeholder="Select User"
                        @input="({ user_id }) => (this.form.user_id = user_id)"
                        :options="users"
                        name="User Name"
                        :searchable="true"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.user_name }}
                        </template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('User Name')"
                        >{{ errors.first("User Name") }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- COMPANY NAME -->

                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Department*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div>
                      <multiselect
                        v-model="department_value"
                        track-by="department_id"
                        label="department_name"
                        placeholder="Select Department"
                        @input="
                          ({ department_id }) =>
                            (this.form.department_id = department_id)
                        "
                        :options="departments"
                        name="Department Name"
                        :searchable="true"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.department_name }}
                        </template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Department Name')"
                        >{{ errors.first("Department Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Designation*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div>
                      <multiselect
                        v-model="designation_value"
                        track-by="designation_id"
                        label="designation_name"
                        placeholder="Select Designation"
                        @input="
                          ({ designation_id }) =>
                            (this.form.designation_id = designation_id)
                        "
                        :options="designations"
                        name="Designation Name"
                        :searchable="true"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.designation_name }}
                        </template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Designation Name')"
                        >{{ errors.first("Designation Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Status*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-switch v-model="switch1" />
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import companyService from "@/services/companyService.js";
import employeeService from "@/services/employeeService.js";
import departmentService from "@/services/departmentService.js";
import designationService from "@/services/designationService.js";
import UserService from "@/services/UserService.js";
import { BASEURL } from "@/config/index.js";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  data() {
    return {
      excelurl: BASEURL(),
      switch1: true,
      submitStatus: true,
      User: "Select User",
      form: {
        company_id: "",
        department_id: "",
        designation_id: "",
        user_id: "",
        employment_active: 1,
      },
      user_value: [],
      company_value: [],
      department_value: [],
      designation_value: [],
      companies: [],
      departments: [],
      designations: [],
      users: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },
    uploadExcel: function (event) {
      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
        .post(that.excelurl + `v1/employment/import/excel`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (res) {
          that.$vs.loading.close();
        })
        .catch(function (err) {
          that.$vs.loading.close();
          that.$vs.notify({
            title: "Error!",
            text: err,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          this.submitStatus = false;
          let payload = {
            company_id: this.form.company_id,
            department_id: this.form.department_id,
            designation_id: this.form.designation_id,
            user_id: this.form.user_id,
            employment_active: this.switch1 ? "1" : "2",
          };
          employeeService
            .addEmployee(payload)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshempTable", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
            });
        }
      });
    },
    // COMPANY LIST
    getCompanyList: function () {
      companyService
        .getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
            if (this.companies.length > 0) {
              this.company_value = this.companies[0];
              this.form.company_id = this.company_value.company_id;
            }
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "Unable to get Companies",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    // DEPARTMENT LIST
    getDepartmentList: function (company_id) {
      departmentService
        .getAllDepartmentsByCompany(company_id)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.departments = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "Unable to get Departments",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    //  DESIGNATION LIST
    getDesignationList: function (company_id) {
      designationService
        .getAllDesignationByCompany(company_id)
        .then((response) => {
          const { data } = response;
          if (data.err) {
          } else {
            this.designations = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "Unable to get Designation",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    //  USER LIST
    getUserList: function () {
      UserService.UserList()
        .then((response) => {
          const { data } = response;
          if (data.err) {
          } else {
            this.users = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "Unable to get Users List",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.user_value = [];
      this.company_value = [];
      this.department_value = [];
      this.designation_value = [];
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    onChangeCompany(selected) {
      this.getDepartmentList(selected.company_id);
      this.getDesignationList(selected.company_id);
    },
  },
  mounted() {
    this.getUserList();
    this.getCompanyList();

    this.gridApi = this.gridOptions.api;
  },
};
</script>
